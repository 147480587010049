$primary-color: #47455C;
$tint-color: #ff1b55;
//$tint-color:#c6cfff;
$backgroundColor: #ffffff;
$font-color-title: #1a1a1a;
$font-color-text: #9c9c9c;
$max-width: 1600px;
@mixin size ($width, $height){
  width:$width;
  height:$height;
}

@mixin flex ($x, $y, $z){
  display: flex;
  justify-content: $x;
  align-items: $y;
  flex-direction: $z;
}
