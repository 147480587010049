.about-container{
  @include size(100%, 100%);
  //@include flex(center, center, column);
  padding-top:70px;
}

.about-page{
  justify-content: space-between;
}

.about-image{
  height:30vw;
  width:30vw;
  max-width: 600px;
  max-height: 600px;
  min-height: 500px;
  min-width: 500px;
}


@media screen and (max-width: 1300px) {
  .about-image{
    height:30vw;
    width:30vw;
    min-height: 300px;
    min-width: 300px;
  }
}


@media screen and (max-width: 1100px) {
    .about-page{
      flex-direction: column !important;
    }

}

