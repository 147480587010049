//.hamburger-container {
//  width: 40px;
//  height: 40px;
//  display: flex;
//  flex-direction: column;
//  align-items: center;
//  justify-content: center;
//  cursor: pointer;
//}
//
//.stick {
//  width: 40px;
//  height: 8px;
//  border-radius: 4px;
//  margin-bottom: 15px;
//  background-color:  #2DFDB9;
//  display: inline-block;
//}
//
//.stick:last-child {
//  margin-bottom: 0px;
//}
//
//.stick-1.open {
//  animation: stick-1-open .6s ease-out forwards;
//}
//
//.stick-2.open {
//  animation: stick-2-open .6s linear forwards;
//}
//
//.stick-3.open {
//  animation: stick-3-open .6s linear forwards;
//}
//
//@keyframes stick-1-open {
//  0%   {width: 80px;}
//  40%  {background-color: #ff1456; width: 8px; transform: translate(40px, 0px);}
//  75%, 80%  {width: 8px; transform: translate(40px, -50px); animation-timing-function: cubic-bezier(0,1,1,1);}
//  100% {background-color: #ff1456; width: 8px; transform: translate(35px, 46px);}
//}
//
//@keyframes stick-2-open {
//  80%  {background-color: #2DFDB9; transform: translate(0px, 0px) rotate(0deg);}
//  100% {background-color: #ff1456; transform: translate(8px, 0px) rotate(40deg);}
//}
//
//@keyframes stick-3-open {
//  80%  {background-color: #2DFDB9; transform: translate(0px, 0px) rotate(0deg);}
//  100% {background-color: #ff1456; transform: translate(8px, -23px) rotate(-40deg);}
//}
//
//.stick-1.close {
//  width: 8px;
//  transform: translate(27px, 26px);
//  animation: stick-1-close .6s ease-out forwards;
//}
//
//.stick-2.close {
//  transform: translate(0px, 0px) rotate(40deg);
//  animation: stick-2-close .6s ease-out forwards;
//}
//
//.stick-3.close {
//  transform: translate(0px, -23px) rotate(-40deg);
//  animation: stick-3-close .6s ease-out forwards;
//}
//
//@keyframes stick-1-close {
//  0%, 70% {width: 0px;}
//  100%    {width: 80px; transform: translate(0, 0);}
//}
//
//@keyframes stick-2-close {
//  0%   {background-color: #ff1456; width: 80px;}
//  20%  {background-color: #ff1456; width: 8px; transform: translate(0, 0px) rotate(40deg);}
//  40%  {background-color: #2DFDB9; width: 0px;}
//  65%  {transform: translate(0, -70px); animation-timing-function: cubic-bezier(0,1,1,1);}
//  80%  {width: 0px;}
//  100% {width: 80px; transform: translate(0, 0px);}
//}
//
//@keyframes stick-3-close {
//  0%   {background-color: #ff1456; width: 80px;}
//  20%  {background-color: #ff1456; width: 8px; transform: translate(0, -23px) rotate(-40deg);}
//  40%  {background-color: #2DFDB9;}
//  65%  {transform: translate(0, -93px); animation-timing-function: cubic-bezier(0,1,1,1);}
//  90%  {width: 8px;}
//  100% {width: 80px; transform: translate(0, 0px);}
//}

.nav-icon {
  margin: 0;
  width: 40px;
}

.nav-icon:after,
.nav-icon:before,
.nav-icon div {
  background-color: #FF1B55;
  border-radius: 3px;
  content: '';
  display: block;
  height: 5px;
  margin: 7px 0;
  transition: all .2s ease-in-out;
}

.nav-icon.open:before {
  transform: translateY(12px) rotate(135deg);
}

.nav-icon.open:after {
  transform: translateY(0px) rotate(-135deg);
}

.nav-icon.open div{
  transform: scale(0);
}
