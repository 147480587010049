.event-container{
  display:grid;
  @include size(100%, 100%);
  @include flex(center, center, column);

}

.event-wrapper{
  display:grid;
  width:100%;
  padding:50px;
  justify-items: center;
  grid-template-columns: repeat(auto-fill, minmax(35vw, 1fr));

}


.event-card-container{
  @include flex(center, flex-start, column);
  @include size(40vw, 70vh);
  margin: 40px 0;
  transition: all 500ms ease-in-out;
  transform: scale(1.0);

    h2{
      font-size: 40px;
      //color: #ce3e43;
      //text-shadow: 1px 1px 30px #CE2A2A;

      color: #ffffff;
      text-shadow: 1px 1px 20px rgba(255,230,230, 0.4);
    }

  p{
    font-size: 24px;
  }

  hr{
    width:100%;
    margin:30px 0;
    color:white;

  }

  &:hover{
    transition: all 200ms ease-in-out;
    transform: scale(1.02);
    cursor:pointer;
  }

}

.event-card-image{
  @include size(100%, 100%);
  background-position:center;
  background-size: cover;
  background-repeat: no-repeat;
}

.event-card-information{
  @include flex(center, flex-start, column);
  @include size(100%, auto);


}
