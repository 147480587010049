.custom-button{
  width: 140px;
  height: 50px;
  background: $tint-color;
  @include flex(center, center, row);
  -webkit-box-shadow: 0px 5px 5px 0px rgba(133,133,133,1);
  font-size: 18px;
  color:white !important;
  text-align: center!important;
  border: none;

  &:active{
    -webkit-box-shadow: none;
  }
}