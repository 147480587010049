.general-div{
  width:100%;
  height:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: $max-width;
  &.padding{
    padding-top: 50px;
  }
  //padding-top: 50px;
}

.opening-hours-container {
  @include size(100%, 100%);
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  li {
    margin-left: 15px;
  }
}

.opening-hours-title{
  width: 100%;
  max-width: 500px;
  @include flex(flex-start, flex-start, column);
}

.opening-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 50px;
  div {
    width: 100%;
    padding:25px 0;
  }
}

.opening-grid-closed {
  display: grid;
  grid-template-columns: 1fr;

  grid-column-gap: 50px;
  div {
    width: 100%;
    padding:25px 0;
    height:200px;
  }
}

.opening-flex{
  @include flex(flex-start, center, column);
  div {
    width: 100%;
    padding:25px 0;
  }
}

@media screen and (max-width: 1100px) {
  .opening-hours-container {
    @include flex(center, center, column);
  }

  .opening-grid {
    grid-template-columns: 1fr 1fr;
    width:100%;
    max-width: 500px;
  }

  .opening-flex{
    @include flex(flex-start, flex-start, row);
    width: 100%;
    max-width: 500px;
  }
}

@media screen and (max-width: 600px) {
  .opening-grid {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }

  .opening-flex{
    @include flex(flex-start, flex-start, column);
    max-width: none;
  }
}
