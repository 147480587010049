$distance: 100px !default;


.animation-container{
  width:100%;
  height:100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: $max-width;
  margin-top: 50px;

  div{
    margin:0 !important;
  }
}

.success-container{
  position: absolute;
  display:block;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);

  &.none{
    display: none;
  }

}


//Aos animations
[data-aos^='fade-custom'][data-aos^='fade-custom'] {
  opacity: 0;
  transition-property: opacity, transform;

  &.aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

[data-aos='fade-up-custom'] {
  transform: translate3d(0, $distance, 0);
}

[data-aos='fade-down-custom'] {
  transform: translate3d(0, -$distance, 0);
}

[data-aos='fade-right-custom'] {
  transform: translate3d(-$distance, 0, 0);
}

[data-aos='fade-left-custom'] {
  transform: translate3d($distance, 0, 0);
}


[data-aos='fade-left-pc'] {
  transform: translate3d($distance, 0, 0);
}


[data-aos='fade-up-right-custom'] {
  transform: translate3d(-$distance, $distance, 0);
}

[data-aos='fade-up-left-custom'] {
  transform: translate3d($distance, $distance, 0);
}

[data-aos='fade-down-right-custom'] {
  transform: translate3d(-$distance, -$distance, 0);
}

[data-aos='fade-down-left-custom'] {
  transform: translate3d($distance, -$distance, 0);
}


@media screen and (max-width: 1100px) {


  .animation-container{
    justify-content: center;
    align-items: center;
  }


  [data-aos='fade-right-custom'] {
    transform: translate3d(0, $distance, 0);
  }

  [data-aos='fade-left-custom'] {
    transform: translate3d(0, $distance, 0);
  }

  [data-aos='fade-left-pc'] {
    transform: translate3d(-$distance, 0, 0);
  }

}