.section-wrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(300px, 1fr));
  padding: 100px 0;
  position: relative;
  max-width: $max-width;
  grid-auto-flow: dense;
  width:100%;

  &.no-padding{
    padding:0;
  }
}

.section-image-wrapper {
  display: flex;
  justify-content: flex-start;
  width:100%;

  &.mobile{
    display: none;
  }

  .section-image {
    width: 30vw;
    height: 30vw;
    min-height: 250px;
    min-width: 250px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;


  }
}

.section-shape {
  position: absolute;
  width: 20vw;
  z-index: -1;
  height: auto;

  &.left{
    top:15%;
    left:-45%;
  }

  &.right{
    top:25%;
    right:-45%;
  }
}

.section-text {
  display: flex;
  flex-direction: column;
  width:100%;

  h2{
    width:100%;
    max-width: 500px;
  }
  P{
    text-align: justify;
    max-width: 500px;
  }

}

.section-header {
  width:100%;
  max-width: 500px;
  margin-bottom: 0;
  color: $tint-color;
}


@media screen and (max-width: 1100px) {
  .section-wrapper {
    grid-template-columns: 1fr;
    width:100%;
  }

  .section-header{
    text-align: center !important;
  }
  .section-text {
    width: 100%;
    align-items: center!important;
    padding-bottom: 25px;
    text-align: center;
  }

  .section-image-wrapper {
    width:100%;
    display: none;
    @include flex(center, center, column);

    &.pc{
      display: none;
    }

    &.mobile{
      @include flex(center, center, column);
    }
  }
}

@media screen and (max-width: 600px) {
  .section-image {
    @include flex(center, center, column);
    width: 50vw;
    height: 50vw;
    min-height: 200px;
    min-width: 200px;
  }

  .section-wrapper{
    padding:50px 0;
  }
}
