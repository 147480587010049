.title-container{
  width: 100%;
  height:auto;
  @include flex(center, flex-start, column);
  padding: 50px 0 0 0;

  h2{
     color:$font-color-title;
  }

  p{
    font-size: 25px;
  }
}
