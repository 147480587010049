//https://codepen.io/Samuel87/pen/yLLezrJ

$input-field: (
        'placeholder-color':    #a0aec0,
        'background-color':     white,
        'border-color':         #e2e8f0,
        'border-color-focus':   #a0aec0,
        'error-color':          #f56565,
        'success-color':        #48AD2C,
        'border-color-success': #48AD2C,
        'label-color':          black,
        'disabled-foreground':  #ccc,
        'disabled-background':  #f0f0f0,
        'padding':              12px 16px,
        'font-size-input':      16px,
        'font-size-label':      16px,
        'border-width':         1px,
        'border-radius':        .25rem,
        'shadow':               unquote('0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06)'),
);

.asrr-input-container {
  display: inline-flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  transition: all 200ms;
  margin:20px 0;

  textarea {
    min-height: 160px;
    resize: none;
    width: 100%;
  }

  .asrr-input{
    max-width: 100%;
    overflow-x: hidden;
    width:100%;
    height:50px;
    transition: all 200ms ease-in-out;
    box-sizing:border-box;
    padding: 8px 12px;
    outline: 0;
    text-align: left !important;
    font: inherit;
    border: 1px solid map-get($input-field, 'border-color');
    border-radius: 4px;
    //box-shadow: map-get($input-field, 'shadow');

    &:focus{
      border: 2px solid map-get($input-field, 'border-color-focus');
      transition: all 200ms ease-in-out;
    }

    &::placeholder {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &.success{
      color: map-get($input-field, 'success-color');
      border-color: lighten(map-get($input-field, 'success-color'), 30%);
      background-color: lighten(map-get($input-field, 'success-color'), 50%);

      &:focus{
        border-color: lighten(map-get($input-field, 'success-color'), 10%);
      }

      &::placeholder {
        color: map-get($input-field, 'success-color');
      }
    }

    &.error{
      color: map-get($input-field, 'error-color');
      border-color: lighten(map-get($input-field, 'error-color'), 20%);
      background-color: lighten(map-get($input-field, 'error-color'), 28%);

      &:focus{
        border-color: lighten(map-get($input-field, 'error-color'), 5%);
      }


      &::placeholder {
        color: map-get($input-field, 'error-color');
      }
    }

    &.disabled{
      color: map-get($input-field, 'disabled-foreground');
      border-color: map-get($input-field, 'disabled-background');
      background-color: map-get($input-field, 'disabled-background');
      pointer-events: none;
      box-shadow: none;


      &::placeholder {
        color: map-get($input-field, 'disabled-foreground');
      }
    }
  }
}

.asrr-input-label{
  margin-bottom:10px;

  &.success{
    color:map-get($input-field, 'success-color');
  }

  &.error{
    color:map-get($input-field, 'error-color');
  }

  &.disabled{
    color: map-get($input-field, 'disabled-foreground');
  }
}

.no-fill {
  input,
  textarea {
    background-color: white;
  }
}


// STAGE

.text-white > .input-label {
  color: black;
}