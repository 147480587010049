.gallery-container{
  width:100%;
  height:100%;
  margin-top: 50px;

}


@media screen and (max-width: 600px) {
  .gallery-container{
    margin-top: 25px;
  }
}