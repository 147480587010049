.landing-container {
  @include size(100vw, 100vh);
  @include flex(space-evenly, flex-start, row);
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  margin: 0 -100px;

  &:after{
    content:"";
    width:100%;
    height:100%;
    background-color: #1a1a1a;
    opacity: 0.3;
    position: absolute;
    left:0;
    top:0;
    z-index: 2;

  }
}

.landing-video {
  z-index: 1;
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  right: 0;
  bottom: 0;
  object-fit:cover;
}

.landing-image {
  display: flex;
  width: 40vw;
  height: 35vw;

}

.landing-slogan {
  margin-top: 100px;
  position: relative;
  z-index: 100;
  @include flex(flex-start, center, column);
  max-width: 900px;

  h2{
    color:white;
  }
}

@media screen and (max-width: 800px) {
  .landing-container {
    @include size(100vw, 50vh);
    @include flex(space-evenly, flex-start, row);
    margin: 0 -50px;
  }

  .landing-video {
    z-index: 1;
    position: absolute;
    width: 100%;
    min-height: 100%;
    right: 0;
    top: 0;
    object-fit:cover;
  }
}

@media screen and (max-width: 400px) {
  .landing-container {
    @include flex(space-evenly, flex-start, row);
    margin: 0 -25px;
  }
}


//
//@media screen and (max-width: 1100px) {
//  .landing-container {
//    @include size(100vw, 60vh);
//    @include flex(flex-start, center, column);
//    box-sizing: border-box;
//    margin: 0 -100px;
//    //padding:100px 0;
//  }
//
//  .landing-slogan {
//    @include flex(center, center, column);
//    margin:0;
//  }
//}
//
//@media screen and (max-width: 900px) {
//  .landing-slogan{
//    max-width:100%;
//    width:100%;
//    text-align: center;
//  }
//
//  .landing-image {
//    display: flex;
//    width: 50vw;
//    height: 50vw;
//  }
//}
