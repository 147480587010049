.contact{
  position: relative;
  justify-content: space-around;
  align-items: flex-start;
  height:auto;
}

.contact-map-wrapper{
  width:100%;
  height: 100%;
  @include flex(center, flex-end, column!important);

  iframe{
    width: 30vw;
    height: 30vw;
    min-height: 350px;
    min-width: 350px;
    position: relative;
  }
}

@media screen and (max-width: 1100px) {
  .contact{
    @include flex(center, center, column!important);
  }
  .contact-map-wrapper{
    margin:50px 0;
    width: 500px;
    @include flex(flex-start, flex-start, column!important);

  }
}

@media screen and (max-width: 600px) {
  .contact{
    @include flex(center, center, column!important);
  }
  .contact-map-wrapper{
    width:100%;
    iframe{
      min-width: 0;
      width: 100%;
      height: 80vw;
    }
  }
}
