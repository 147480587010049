$list: (1, 2, 3, 4, 5, 6);

.header-container {
  @include size(100%, 70px);
  height: 70px;
  @include flex(center, center, row);
  transition: 200ms all ease-in;
  position: fixed;
  z-index: 1000;

}

.header-logo{
  width:20%;
  height:100%;
  position: relative;
  img{
    width:200px;
    height: auto;
    position: absolute;
    left:0;
    top:-20px;
  }
}

.header-hamburger {
  display: none;
}

.header-menu {
  @include size(100%, 100%);
  @include flex(center, center, row);
  padding: 0 20px;
  flex: 2;
  list-style: none;
  text-decoration: none;

  li {
    @include size(auto, 100%);
    @include flex(center, center, row);
    position: relative;
    svg{
      display: none;
    }

    &:hover {
      cursor: pointer;
    }

    a {
      padding: 26px 30px;
      font-size: 16px;
    }

    &:hover a {
      color: $tint-color;

    }
  }

  .signup-button {
    @include flex(center, center, row);
    @include size(120px, 50px);
    border-radius: 25px;
    text-decoration: none;
    background-image: linear-gradient(to top, #ff0844 0%, #ff2761 100%);
    transition: .400ms all ease-in-out;

    a {
      color: white !important;
    }

    &:hover {
      background-image: linear-gradient(to top, #e70840 0%, #ff2761 100%);
      cursor: pointer;
    }
  }

  .dropdown {
    position: absolute;
    top: 70px;
    left: 0;
    @include size(200%, auto);
    border-top: 1px #ffffff solid;
    background-color: #fbfbfb;
    opacity: 0;
    pointer-events: none;
    transition: 200ms ease-in all;

    li {
      @include flex(flex-start, center, row);
      border-bottom: 1px #ffffff solid;

      &:hover {
        background-color: #f7f7f7;
        cursor: pointer;
      }

      &:hover {
        a {
          color: $tint-color;
        }
      }

      a {
        color: $primary-color;
      }
    }
  }

  li:hover .dropdown {
    opacity: 1;
    pointer-events: all;
  }
}

.active-link {
  color: $tint-color;
}

@media screen and (max-width: 1100px) {
  .header-menu {
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100vh;
    overflow-y: scroll;
    @include size(0, 100%);
    background-color: white;
    @include flex(flex-start, flex-start, column);
    transition: 600ms ease-in-out all;
    padding: 100px 0 0 0px;
    width: 100vw;
    transform: scaleX(0);
    transform-origin: left;

    //Hide scrollbar
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;

    li {
      padding: 0 0 0 50px;
      @include size(100%, auto);
      @include flex(flex-start, flex-start, column);
      svg{
        display: inline-block;
        margin-left: 20px;
        transition: 300ms ease-in-out transform;
        transform: rotate(0deg);
      }

      a {
        padding: 20px 0;
        margin: 20px 0;
        font-size: 26px;
      }

      .dropdown {
        top: 0;
        position: relative;
        @include size(100%, 100%);
        display: none;
        opacity: 1;
        li {
          @include flex(flex-start, center, row);
          a {
            font-size: 18px;
          }
        }
      }

      &.open{
        svg{
          transition: 50ms ease-in-out transform;
          transform: rotate(180deg);
        }
        .dropdown {
          display: inline-block;
        }
      }
    }

    @each $i in ($list) {
      li:nth-of-type(#{$i}) {
        transform: translateX(-100vw);
        transition: 500ms ease-in-out all;
        transition-delay: $i*50ms;
        opacity: 0;
      }
    }

    &.open {
      transform: scaleX(1);
      transition: 400ms ease-in-out all;
      @each $i in ($list) {
        li:nth-of-type(#{$i}) {
          transition: 500ms ease-in-out all;
          transition-delay: $i*50ms;
          transform: translateX(0);
          opacity: 1;
        }
      }
    }

    .signup-button {
      display: none;
    }
  }

  .header-hamburger {
    @include size(100%, 70px);
    display: flex;
    @include flex(center, flex-end, column);
    z-index: 100;
    padding: 0 50px 0 0;
    box-sizing: border-box;
  }
}


@media screen and (max-width: 600px) {

  .header-logo{

    img{
      top:-10px;
      width:120px;
    }
  }

  .header-menu {
    li {
      padding: 0 0 0 25px;

      a{
        padding:10px 0;
        font-size: 26px;
      }
    }
  }

  .header-hamburger {
    padding: 0 25px 0 0;
  }
}
