@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans|Source+Sans+Pro&display=swap);
/** Proxima Nova **/
/*@font-face {*/
/*font-family: 'proxima_nova_altblack';*/
/*!*src: url('Mark Simonson - Proxima Nova Alt Black-webfont.eot');*!*/
/*!*src: url('Mark Simonson - Proxima Nova Alt Black-webfont.eot?#iefix') format('embedded-opentype'),*!*/
/*url('Mark Simonson - Proxima Nova Alt Black-webfont.woff2') format('woff2'),*/
/*url('Mark Simonson - Proxima Nova Alt Black-webfont.woff') format('woff'),*/
/*url('Mark Simonson - Proxima Nova Alt Black-webfont.ttf') format('truetype'),*/
/*url('Mark Simonson - Proxima Nova Alt Black-webfont.svg#proxima_nova_altblack') format('svg');*/
/*font-weight: normal;*/
/*font-style: normal;*/
/*}*/
@font-face {
  font-family: 'proxima_nova_altbold';
  src: url("/static/media/Mark Simonson - Proxima Nova Alt Bold-webfont.7a949b68.eot");
  src: url("/static/media/Mark Simonson - Proxima Nova Alt Bold-webfont.7a949b68.eot"?#iefix) format("embedded-opentype"), url("/static/media/Mark Simonson - Proxima Nova Alt Bold-webfont.d95e053c.woff2") format("woff2"), url("/static/media/Mark Simonson - Proxima Nova Alt Bold-webfont.fa0710b8.woff") format("woff"), url("/static/media/Mark Simonson - Proxima Nova Alt Bold-webfont.d728a3db.ttf") format("truetype"), url("/static/media/Mark Simonson - Proxima Nova Alt Bold-webfont.bfe2bf49.svg"#proxima_nova_altbold) format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova';
  src: url("/static/media/Mark Simonson - Proxima Nova Alt Regular-webfont.e802340f.eot");
  src: url("/static/media/Mark Simonson - Proxima Nova Alt Regular-webfont.e802340f.eot"?#iefix) format("embedded-opentype"), url("/static/media/Mark Simonson - Proxima Nova Alt Regular-webfont.12ccd551.woff") format("woff"), url("/static/media/Mark Simonson - Proxima Nova Alt Regular-webfont.0b420bac.ttf") format("truetype"), url("/static/media/Mark Simonson - Proxima Nova Alt Regular-webfont.31015419.svg"#proxima_nova_altregular) format("svg");
  font-weight: normal;
  font-style: normal; }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on April 4, 2015 */
@font-face {
  font-family: 'proxima_nova_scosfthin';
  src: url("/static/media/Mark Simonson - Proxima Nova ScOsf Thin-webfont.e2ecf5fa.eot");
  src: url("/static/media/Mark Simonson - Proxima Nova ScOsf Thin-webfont.e2ecf5fa.eot"?#iefix) format("embedded-opentype"), url("/static/media/Mark Simonson - Proxima Nova ScOsf Thin-webfont.572779bf.woff") format("woff"), url("/static/media/Mark Simonson - Proxima Nova ScOsf Thin-webfont.ba783194.ttf") format("truetype"), url("/static/media/Mark Simonson - Proxima Nova ScOsf Thin-webfont.57204409.svg"#proxima_nova_scosfthin) format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'proxima_nova_ltsemibold';
  src: url("/static/media/Mark Simonson - Proxima Nova Semibold-webfont.bbec7fc3.eot");
  src: url("/static/media/Mark Simonson - Proxima Nova Semibold-webfont.bbec7fc3.eot"?#iefix) format("embedded-opentype"), url("/static/media/Mark Simonson - Proxima Nova Semibold-webfont.4a2a396b.woff2") format("woff2"), url("/static/media/Mark Simonson - Proxima Nova Semibold-webfont.7bd0a4c6.ttf") format("truetype"), url("/static/media/Mark Simonson - Proxima Nova Semibold-webfont.6c1b0f2a.svg"#proxima_nova_ltsemibold) format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'proxima_nova_ltthin';
  src: url("/static/media/Mark Simonson - Proxima Nova Thin-webfont.e2d89c72.eot");
  src: url("/static/media/Mark Simonson - Proxima Nova Thin-webfont.e2d89c72.eot"?#iefix) format("embedded-opentype"), url("/static/media/Mark Simonson - Proxima Nova Thin-webfont.0e628fcf.woff2") format("woff2"), url("/static/media/Mark Simonson - Proxima Nova Thin-webfont.17aa4534.woff") format("woff"), url("/static/media/Mark Simonson - Proxima Nova Thin-webfont.532aaef1.ttf") format("truetype"), url("/static/media/Mark Simonson - Proxima Nova Thin-webfont.73313d22.svg"#proxima_nova_ltthin) format("svg");
  font-weight: normal;
  font-style: normal; }

.header-container {
  width: 100%;
  height: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  transition: 200ms all ease-in;
  position: fixed;
  z-index: 1000; }

.header-logo {
  width: 20%;
  height: 100%;
  position: relative; }
  .header-logo img {
    width: 200px;
    height: auto;
    position: absolute;
    left: 0;
    top: -20px; }

.header-hamburger {
  display: none; }

.header-menu {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 0 20px;
  flex: 2 1;
  list-style: none;
  text-decoration: none; }
  .header-menu li {
    width: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: relative; }
    .header-menu li svg {
      display: none; }
    .header-menu li:hover {
      cursor: pointer; }
    .header-menu li a {
      padding: 26px 30px;
      font-size: 16px; }
    .header-menu li:hover a {
      color: #ff1b55; }
  .header-menu .signup-button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 120px;
    height: 50px;
    border-radius: 25px;
    text-decoration: none;
    background-image: linear-gradient(to top, #ff0844 0%, #ff2761 100%);
    transition: .400ms all ease-in-out; }
    .header-menu .signup-button a {
      color: white !important; }
    .header-menu .signup-button:hover {
      background-image: linear-gradient(to top, #e70840 0%, #ff2761 100%);
      cursor: pointer; }
  .header-menu .dropdown {
    position: absolute;
    top: 70px;
    left: 0;
    width: 200%;
    height: auto;
    border-top: 1px #ffffff solid;
    background-color: #fbfbfb;
    opacity: 0;
    pointer-events: none;
    transition: 200ms ease-in all; }
    .header-menu .dropdown li {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      border-bottom: 1px #ffffff solid; }
      .header-menu .dropdown li:hover {
        background-color: #f7f7f7;
        cursor: pointer; }
      .header-menu .dropdown li:hover a {
        color: #ff1b55; }
      .header-menu .dropdown li a {
        color: #47455C; }
  .header-menu li:hover .dropdown {
    opacity: 1;
    pointer-events: all; }

.active-link {
  color: #ff1b55; }

@media screen and (max-width: 1100px) {
  .header-menu {
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100vh;
    overflow-y: scroll;
    width: 0;
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    transition: 600ms ease-in-out all;
    padding: 100px 0 0 0px;
    width: 100vw;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: left;
            transform-origin: left;
    -ms-overflow-style: none; }
    .header-menu::-webkit-scrollbar {
      display: none; }
    .header-menu li {
      padding: 0 0 0 50px;
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column; }
      .header-menu li svg {
        display: inline-block;
        margin-left: 20px;
        transition: 300ms ease-in-out transform;
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg); }
      .header-menu li a {
        padding: 20px 0;
        margin: 20px 0;
        font-size: 26px; }
      .header-menu li .dropdown {
        top: 0;
        position: relative;
        width: 100%;
        height: 100%;
        display: none;
        opacity: 1; }
        .header-menu li .dropdown li {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: row; }
          .header-menu li .dropdown li a {
            font-size: 18px; }
      .header-menu li.open svg {
        transition: 50ms ease-in-out transform;
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg); }
      .header-menu li.open .dropdown {
        display: inline-block; }
    .header-menu li:nth-of-type(1) {
      -webkit-transform: translateX(-100vw);
              transform: translateX(-100vw);
      transition: 500ms ease-in-out all;
      transition-delay: 50ms;
      opacity: 0; }
    .header-menu li:nth-of-type(2) {
      -webkit-transform: translateX(-100vw);
              transform: translateX(-100vw);
      transition: 500ms ease-in-out all;
      transition-delay: 100ms;
      opacity: 0; }
    .header-menu li:nth-of-type(3) {
      -webkit-transform: translateX(-100vw);
              transform: translateX(-100vw);
      transition: 500ms ease-in-out all;
      transition-delay: 150ms;
      opacity: 0; }
    .header-menu li:nth-of-type(4) {
      -webkit-transform: translateX(-100vw);
              transform: translateX(-100vw);
      transition: 500ms ease-in-out all;
      transition-delay: 200ms;
      opacity: 0; }
    .header-menu li:nth-of-type(5) {
      -webkit-transform: translateX(-100vw);
              transform: translateX(-100vw);
      transition: 500ms ease-in-out all;
      transition-delay: 250ms;
      opacity: 0; }
    .header-menu li:nth-of-type(6) {
      -webkit-transform: translateX(-100vw);
              transform: translateX(-100vw);
      transition: 500ms ease-in-out all;
      transition-delay: 300ms;
      opacity: 0; }
    .header-menu.open {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      transition: 400ms ease-in-out all; }
      .header-menu.open li:nth-of-type(1) {
        transition: 500ms ease-in-out all;
        transition-delay: 50ms;
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1; }
      .header-menu.open li:nth-of-type(2) {
        transition: 500ms ease-in-out all;
        transition-delay: 100ms;
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1; }
      .header-menu.open li:nth-of-type(3) {
        transition: 500ms ease-in-out all;
        transition-delay: 150ms;
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1; }
      .header-menu.open li:nth-of-type(4) {
        transition: 500ms ease-in-out all;
        transition-delay: 200ms;
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1; }
      .header-menu.open li:nth-of-type(5) {
        transition: 500ms ease-in-out all;
        transition-delay: 250ms;
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1; }
      .header-menu.open li:nth-of-type(6) {
        transition: 500ms ease-in-out all;
        transition-delay: 300ms;
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1; }
    .header-menu .signup-button {
      display: none; }
  .header-hamburger {
    width: 100%;
    height: 70px;
    display: flex;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    z-index: 100;
    padding: 0 50px 0 0;
    box-sizing: border-box; } }

@media screen and (max-width: 600px) {
  .header-logo img {
    top: -10px;
    width: 120px; }
  .header-menu li {
    padding: 0 0 0 25px; }
    .header-menu li a {
      padding: 10px 0;
      font-size: 26px; }
  .header-hamburger {
    padding: 0 25px 0 0; } }

.nav-icon {
  margin: 0;
  width: 40px; }

.nav-icon:after,
.nav-icon:before,
.nav-icon div {
  background-color: #FF1B55;
  border-radius: 3px;
  content: '';
  display: block;
  height: 5px;
  margin: 7px 0;
  transition: all .2s ease-in-out; }

.nav-icon.open:before {
  -webkit-transform: translateY(12px) rotate(135deg);
          transform: translateY(12px) rotate(135deg); }

.nav-icon.open:after {
  -webkit-transform: translateY(0px) rotate(-135deg);
          transform: translateY(0px) rotate(-135deg); }

.nav-icon.open div {
  -webkit-transform: scale(0);
          transform: scale(0); }

.landing-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: row;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  margin: 0 -100px; }
  .landing-container:after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #1a1a1a;
    opacity: 0.3;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2; }

.landing-video {
  z-index: 1;
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  right: 0;
  bottom: 0;
  object-fit: cover; }

.landing-image {
  display: flex;
  width: 40vw;
  height: 35vw; }

.landing-slogan {
  margin-top: 100px;
  position: relative;
  z-index: 100;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  max-width: 900px; }
  .landing-slogan h2 {
    color: white; }

@media screen and (max-width: 800px) {
  .landing-container {
    width: 100vw;
    height: 50vh;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: row;
    margin: 0 -50px; }
  .landing-video {
    z-index: 1;
    position: absolute;
    width: 100%;
    min-height: 100%;
    right: 0;
    top: 0;
    object-fit: cover; } }

@media screen and (max-width: 400px) {
  .landing-container {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: row;
    margin: 0 -25px; } }

.title-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 50px 0 0 0; }
  .title-container h2 {
    color: #1a1a1a; }
  .title-container p {
    font-size: 25px; }

.event-container {
  display: grid;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

.event-wrapper {
  display: grid;
  width: 100%;
  padding: 50px;
  justify-items: center;
  grid-template-columns: repeat(auto-fill, minmax(35vw, 1fr)); }

.event-card-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 40vw;
  height: 70vh;
  margin: 40px 0;
  transition: all 500ms ease-in-out;
  -webkit-transform: scale(1);
          transform: scale(1); }
  .event-card-container h2 {
    font-size: 40px;
    color: #ffffff;
    text-shadow: 1px 1px 20px rgba(255, 230, 230, 0.4); }
  .event-card-container p {
    font-size: 24px; }
  .event-card-container hr {
    width: 100%;
    margin: 30px 0;
    color: white; }
  .event-card-container:hover {
    transition: all 200ms ease-in-out;
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    cursor: pointer; }

.event-card-image {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

.event-card-information {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: auto; }

.section-wrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(300px, 1fr));
  padding: 100px 0;
  position: relative;
  max-width: 1600px;
  grid-auto-flow: dense;
  width: 100%; }
  .section-wrapper.no-padding {
    padding: 0; }

.section-image-wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%; }
  .section-image-wrapper.mobile {
    display: none; }
  .section-image-wrapper .section-image {
    width: 30vw;
    height: 30vw;
    min-height: 250px;
    min-width: 250px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }

.section-shape {
  position: absolute;
  width: 20vw;
  z-index: -1;
  height: auto; }
  .section-shape.left {
    top: 15%;
    left: -45%; }
  .section-shape.right {
    top: 25%;
    right: -45%; }

.section-text {
  display: flex;
  flex-direction: column;
  width: 100%; }
  .section-text h2 {
    width: 100%;
    max-width: 500px; }
  .section-text P {
    text-align: justify;
    max-width: 500px; }

.section-header {
  width: 100%;
  max-width: 500px;
  margin-bottom: 0;
  color: #ff1b55; }

@media screen and (max-width: 1100px) {
  .section-wrapper {
    grid-template-columns: 1fr;
    width: 100%; }
  .section-header {
    text-align: center !important; }
  .section-text {
    width: 100%;
    align-items: center !important;
    padding-bottom: 25px;
    text-align: center; }
  .section-image-wrapper {
    width: 100%;
    display: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    .section-image-wrapper.pc {
      display: none; }
    .section-image-wrapper.mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column; } }

@media screen and (max-width: 600px) {
  .section-image {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50vw;
    height: 50vw;
    min-height: 200px;
    min-width: 200px; }
  .section-wrapper {
    padding: 50px 0; } }

.contact {
  position: relative;
  justify-content: space-around;
  align-items: flex-start;
  height: auto; }

.contact-map-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column !important; }
  .contact-map-wrapper iframe {
    width: 30vw;
    height: 30vw;
    min-height: 350px;
    min-width: 350px;
    position: relative; }

@media screen and (max-width: 1100px) {
  .contact {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column !important; }
  .contact-map-wrapper {
    margin: 50px 0;
    width: 500px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column !important; } }

@media screen and (max-width: 600px) {
  .contact {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column !important; }
  .contact-map-wrapper {
    width: 100%; }
    .contact-map-wrapper iframe {
      min-width: 0;
      width: 100%;
      height: 80vw; } }

.about-container {
  width: 100%;
  height: 100%;
  padding-top: 70px; }

.about-page {
  justify-content: space-between; }

.about-image {
  height: 30vw;
  width: 30vw;
  max-width: 600px;
  max-height: 600px;
  min-height: 500px;
  min-width: 500px; }

@media screen and (max-width: 1300px) {
  .about-image {
    height: 30vw;
    width: 30vw;
    min-height: 300px;
    min-width: 300px; } }

@media screen and (max-width: 1100px) {
  .about-page {
    flex-direction: column !important; } }

.page-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 100px 0 25px 0;
  overflow-y: hidden; }

.page-wrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(300px, 1fr));
  padding: 0 0 100px 0;
  position: relative;
  max-width: 1600px;
  grid-auto-flow: dense;
  width: 100%; }
  .page-wrapper.no-padding {
    padding: 0; }

.page-image-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%; }
  .page-image-wrapper .page-image {
    width: 30vw;
    height: 30vw;
    min-height: 250px;
    min-width: 250px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }

.page-shape {
  position: absolute;
  width: 20vw;
  z-index: -1;
  height: auto; }
  .page-shape.left {
    top: 15%;
    left: -45%; }
  .page-shape.right {
    top: 25%;
    right: -45%; }

.page-text {
  display: flex;
  flex-direction: column;
  width: 100%; }
  .page-text svg {
    margin-right: 10px;
    font-size: 24px; }
    .page-text svg path {
      fill: #ff1b55; }
  .page-text a {
    margin-bottom: 15px; }
    .page-text a:hover {
      color: #ff1b55; }
  .page-text h2 {
    width: 100%;
    max-width: 500px; }
  .page-text P {
    text-align: justify;
    max-width: 500px; }

.page-header {
  width: 100%;
  max-width: 500px;
  margin-bottom: 0;
  color: #ff1b55; }

.page-bullets {
  margin-top: 25px;
  width: 100%;
  display: grid;
  grid-template-columns: 300px 300px;
  grid-row-gap: 50px; }

.page-regulations {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column; }
  .page-regulations.margin-reverse {
    margin-top: 0;
    margin-bottom: 100px; }
  .page-regulations h2 {
    margin-bottom: 25px; }
  .page-regulations svg {
    margin-right: 10px;
    font-size: 24px; }
    .page-regulations svg path {
      fill: #ff1b55; }
  .page-regulations a {
    margin-bottom: 25px; }
    .page-regulations a:hover {
      color: #ff1b55; }

.bitcare {
  max-width: 500px;
  width: 100%;
  height: auto;
  min-width: 200px; }

@media screen and (max-width: 1100px) {
  .page-container h2 {
    text-align: center !important;
    width: 100%; }
  .page-wrapper {
    grid-template-columns: 1fr;
    width: 100%; }
  .page-header {
    text-align: center !important; }
  .page-text {
    width: 100%;
    align-items: center;
    text-align: center;
    padding-bottom: 25px; }
    .page-text h2 {
      text-align: center; }
    .page-text svg {
      display: block;
      margin: 0 auto 10px auto; }
    .page-text a {
      text-align: center; }
  .page-bullets {
    justify-items: center;
    text-align: center !important; }
  .page-regulations {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    .page-regulations.margin-reverse {
      margin-bottom: 100px; }
    .page-regulations h2 {
      text-align: center; }
    .page-regulations svg {
      display: block;
      margin: 0 auto 10px auto; }
    .page-regulations a {
      text-align: center; }
  .page-image-wrapper {
    width: 100%;
    display: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; } }

@media screen and (max-width: 800px) {
  .page-bullets {
    grid-template-columns: 1fr; } }

@media screen and (max-width: 600px) {
  .page-container {
    padding: 25px 0 25px 0; }
  .page-image {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50vw;
    height: 50vw;
    min-height: 200px;
    min-width: 200px; }
  .page-regulations {
    margin-top: 50px; }
  .page-wrapper {
    padding: 50px 0; } }

.asrr-input-container {
  display: inline-flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  transition: all 200ms;
  margin: 20px 0; }
  .asrr-input-container textarea {
    min-height: 160px;
    resize: none;
    width: 100%; }
  .asrr-input-container .asrr-input {
    max-width: 100%;
    overflow-x: hidden;
    width: 100%;
    height: 50px;
    transition: all 200ms ease-in-out;
    box-sizing: border-box;
    padding: 8px 12px;
    outline: 0;
    text-align: left !important;
    font: inherit;
    border: 1px solid #e2e8f0;
    border-radius: 4px; }
    .asrr-input-container .asrr-input:focus {
      border: 2px solid #a0aec0;
      transition: all 200ms ease-in-out; }
    .asrr-input-container .asrr-input::-webkit-input-placeholder {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .asrr-input-container .asrr-input::-ms-input-placeholder {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .asrr-input-container .asrr-input::placeholder {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .asrr-input-container .asrr-input.success {
      color: #48AD2C;
      border-color: #a1e38f;
      background-color: #e6f7e1; }
      .asrr-input-container .asrr-input.success:focus {
        border-color: #5dce3e; }
      .asrr-input-container .asrr-input.success::-webkit-input-placeholder {
        color: #48AD2C; }
      .asrr-input-container .asrr-input.success::-ms-input-placeholder {
        color: #48AD2C; }
      .asrr-input-container .asrr-input.success::placeholder {
        color: #48AD2C; }
    .asrr-input-container .asrr-input.error {
      color: #f56565;
      border-color: #fbc5c5;
      background-color: #feebeb; }
      .asrr-input-container .asrr-input.error:focus {
        border-color: #f77d7d; }
      .asrr-input-container .asrr-input.error::-webkit-input-placeholder {
        color: #f56565; }
      .asrr-input-container .asrr-input.error::-ms-input-placeholder {
        color: #f56565; }
      .asrr-input-container .asrr-input.error::placeholder {
        color: #f56565; }
    .asrr-input-container .asrr-input.disabled {
      color: #ccc;
      border-color: #f0f0f0;
      background-color: #f0f0f0;
      pointer-events: none;
      box-shadow: none; }
      .asrr-input-container .asrr-input.disabled::-webkit-input-placeholder {
        color: #ccc; }
      .asrr-input-container .asrr-input.disabled::-ms-input-placeholder {
        color: #ccc; }
      .asrr-input-container .asrr-input.disabled::placeholder {
        color: #ccc; }

.asrr-input-label {
  margin-bottom: 10px; }
  .asrr-input-label.success {
    color: #48AD2C; }
  .asrr-input-label.error {
    color: #f56565; }
  .asrr-input-label.disabled {
    color: #ccc; }

.no-fill input,
.no-fill textarea {
  background-color: white; }

.text-white > .input-label {
  color: black; }

.animation-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 1600px;
  margin-top: 50px; }
  .animation-container div {
    margin: 0 !important; }

.success-container {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
  .success-container.none {
    display: none; }

[data-aos^='fade-custom'][data-aos^='fade-custom'] {
  opacity: 0;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform; }
  [data-aos^='fade-custom'][data-aos^='fade-custom'].aos-animate {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }

[data-aos='fade-up-custom'] {
  -webkit-transform: translate3d(0, 100px, 0);
          transform: translate3d(0, 100px, 0); }

[data-aos='fade-down-custom'] {
  -webkit-transform: translate3d(0, -100px, 0);
          transform: translate3d(0, -100px, 0); }

[data-aos='fade-right-custom'] {
  -webkit-transform: translate3d(-100px, 0, 0);
          transform: translate3d(-100px, 0, 0); }

[data-aos='fade-left-custom'] {
  -webkit-transform: translate3d(100px, 0, 0);
          transform: translate3d(100px, 0, 0); }

[data-aos='fade-left-pc'] {
  -webkit-transform: translate3d(100px, 0, 0);
          transform: translate3d(100px, 0, 0); }

[data-aos='fade-up-right-custom'] {
  -webkit-transform: translate3d(-100px, 100px, 0);
          transform: translate3d(-100px, 100px, 0); }

[data-aos='fade-up-left-custom'] {
  -webkit-transform: translate3d(100px, 100px, 0);
          transform: translate3d(100px, 100px, 0); }

[data-aos='fade-down-right-custom'] {
  -webkit-transform: translate3d(-100px, -100px, 0);
          transform: translate3d(-100px, -100px, 0); }

[data-aos='fade-down-left-custom'] {
  -webkit-transform: translate3d(100px, -100px, 0);
          transform: translate3d(100px, -100px, 0); }

@media screen and (max-width: 1100px) {
  .animation-container {
    justify-content: center;
    align-items: center; }
  [data-aos='fade-right-custom'] {
    -webkit-transform: translate3d(0, 100px, 0);
            transform: translate3d(0, 100px, 0); }
  [data-aos='fade-left-custom'] {
    -webkit-transform: translate3d(0, 100px, 0);
            transform: translate3d(0, 100px, 0); }
  [data-aos='fade-left-pc'] {
    -webkit-transform: translate3d(-100px, 0, 0);
            transform: translate3d(-100px, 0, 0); } }

.gallery-container {
  width: 100%;
  height: 100%;
  margin-top: 50px; }

@media screen and (max-width: 600px) {
  .gallery-container {
    margin-top: 25px; } }

.sign-up-container {
  width: 100%;
  height: 2500px;
  border: none;
  overflow-y: hidden;
  overflow-x: hidden; }

.custom-button {
  width: 140px;
  height: 50px;
  background: #ff1b55;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  -webkit-box-shadow: 0px 5px 5px 0px #858585;
  font-size: 18px;
  color: white !important;
  text-align: center !important;
  border: none; }
  .custom-button:active {
    -webkit-box-shadow: none; }

.general-div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1600px; }
  .general-div.padding {
    padding-top: 50px; }

.opening-hours-container {
  width: 100%;
  height: 100%;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .opening-hours-container li {
    margin-left: 15px; }

.opening-hours-title {
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column; }

.opening-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 50px; }
  .opening-grid div {
    width: 100%;
    padding: 25px 0; }

.opening-grid-closed {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 50px; }
  .opening-grid-closed div {
    width: 100%;
    padding: 25px 0;
    height: 200px; }

.opening-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column; }
  .opening-flex div {
    width: 100%;
    padding: 25px 0; }

@media screen and (max-width: 1100px) {
  .opening-hours-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
  .opening-grid {
    grid-template-columns: 1fr 1fr;
    width: 100%;
    max-width: 500px; }
  .opening-flex {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    width: 100%;
    max-width: 500px; } }

@media screen and (max-width: 600px) {
  .opening-grid {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr)); }
  .opening-flex {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    max-width: none; } }

* {
  margin: 0;
  padding: 0;
  font-size: 100%; }

html {
  min-height: 100vh;
  height: 100%; }

body {
  font-family: 'Source Sans Pro', serif !important;
  background-color: #ffffff;
  min-height: 100%; }

.App {
  min-height: 100vh;
  height: 100%;
  overflow-x: hidden !important; }

.App-wrapper {
  min-height: 100vh;
  height: 100%;
  padding: 0 100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

h1, h2, h3, p, a, li {
  font-family: 'Nunito Sa', 'Source Sans Pro', serif; }

h1 {
  font-size: 90px;
  color: white;
  font-weight: bold;
  padding-top: 0.5em;
  margin: 15px 0; }

h2 {
  font-size: 34px;
  margin: 15px 0; }

a {
  font-size: 16px;
  color: inherit;
  list-style: none;
  margin: 0; }

p {
  font-size: 16px;
  text-align: left;
  line-height: 1.7;
  color: #9c9c9c; }

a:hover {
  color: inherit; }

@media screen and (max-width: 1100px) {
  h1 {
    font-size: 50px; }
  h2 {
    font-size: 34px; }
  a {
    color: #47455C;
    font-size: 22px;
    font-weight: lighter !important; } }

@media screen and (max-width: 800px) {
  .App-wrapper {
    padding: 0 50px; } }

@media screen and (max-width: 600px) {
  h1 {
    font-size: 40px; }
  h2 {
    font-size: 24px; }
  a {
    font-size: 22px; } }

@media screen and (max-width: 400px) {
  .App-wrapper {
    padding: 0 25px; } }

