.page-container {
  width: 100%;
  height: 100%;
  @include flex(center, flex-start, column);
  padding: 100px 0 25px 0;
  overflow-y: hidden;
}

.page-wrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(300px, 1fr));
  padding: 0 0 100px 0;
  position: relative;
  max-width: $max-width;
  grid-auto-flow: dense;
  width: 100%;

  &.no-padding {
    padding: 0;
  }
}

.page-image-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .page-image {
    width: 30vw;
    height: 30vw;
    min-height: 250px;
    min-width: 250px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.page-shape {
  position: absolute;
  width: 20vw;
  z-index: -1;
  height: auto;

  &.left {
    top: 15%;
    left: -45%;
  }

  &.right {
    top: 25%;
    right: -45%;
  }
}

.page-text {
  display: flex;
  flex-direction: column;
  width: 100%;


  svg {
    margin-right: 10px;
    font-size: 24px;
    path {
      fill: $tint-color;
    }
  }

  a {
    margin-bottom: 15px;
    &:hover {
      color: $tint-color;
    }
  }

  h2 {
    width: 100%;
    max-width: 500px;
  }

  P {
    text-align: justify;
    max-width: 500px;
  }

}

.page-header {
  width: 100%;
  max-width: 500px;
  margin-bottom: 0;
  color: $tint-color;
}

.page-bullets {
  margin-top: 25px;
  width: 100%;
  display: grid;
  grid-template-columns: 300px 300px;
  grid-row-gap: 50px;
}

.page-regulations {
  &.margin-reverse{
    margin-top: 0;
    margin-bottom: 100px;
  }
  margin-top: 100px;
  @include flex(center, flex-start, column);

  h2{
    margin-bottom: 25px;
  }

  svg {
    margin-right: 10px;
    font-size: 24px;
    path {
      fill: $tint-color;
    }
  }

  a {
    margin-bottom: 25px;
    &:hover {
      color: $tint-color;
    }
  }
}

.bitcare{
  max-width: 500px;
  width:100%;
  height: auto;
  min-width: 200px;
}


@media screen and (max-width: 1100px) {
  .page-container {
    h2{
      text-align: center!important;
      width:100%;
    }
  }

  .page-wrapper {
    grid-template-columns: 1fr;
    width: 100%;
  }


  .page-header {
    text-align: center!important;
  }

  .page-text {
    width: 100%;
    align-items: center;
    text-align: center;
    padding-bottom: 25px;
    h2{
      text-align: center;
    }


    svg {
      display: block;
      margin: 0 auto 10px auto;
    }

    a {
      text-align: center;
    }
  }

  .page-bullets {
    justify-items: center;
    text-align: center!important;
  }

  .page-regulations {
    width: 100%;
    @include flex(center, center, column);
    &.margin-reverse{
      margin-bottom:100px;
    }

    h2{
      text-align: center;
    }

    svg {
      display: block;
      margin: 0 auto 10px auto;
    }

    a {
      text-align: center;
    }
  }


  .page-image-wrapper {
    width: 100%;
    display: none;
    @include flex(center, center, column);
  }
}

@media screen and (max-width: 800px) {
  .page-bullets {
    grid-template-columns: 1fr;
  }

}

@media screen and (max-width: 600px) {
  .page-container{
    padding: 25px 0 25px 0;

  }

  .page-image {
    @include flex(center, center, column);
    width: 50vw;
    height: 50vw;
    min-height: 200px;
    min-width: 200px;
  }

  .page-regulations{
    margin-top: 50px;
  }

  .page-wrapper {
    padding: 50px 0;
  }
}
