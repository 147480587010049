@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');
@import url('fonts/fonts.css');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans&display=swap');
@import "styles/collections/collections";
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans|Source+Sans+Pro&display=swap');
@import '../node_modules/aos/dist/aos.css';


* {
    margin: 0;
    padding: 0;
    font-size: 100%;
}

html{
    min-height: 100vh;
    height:100%;
}

body {
    font-family: 'Source Sans Pro', serif !important;
    background-color: $backgroundColor;
    min-height: 100%;
}

.App{
    min-height: 100vh;
    height:100%;
    overflow-x: hidden !important;
}

.App-wrapper{
    min-height: 100vh;
    height:100%;
    padding: 0 100px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

h1, h2, h3, p, a, li {
    font-family: 'Nunito Sa', 'Source Sans Pro', serif;
}

h1 {
    font-size: 90px;
    color: white;
    font-weight: bold;
    padding-top: 0.5em;
    margin:15px 0;
}

h2 {
    font-size: 34px;
    margin:15px 0;

}

a {
    font-size: 16px;
    color:inherit;
    list-style: none;
    margin:0;
}

p{
    font-size: 16px;
    text-align: left;
    line-height: 1.7;
    color:$font-color-text;
}

a:hover{
    color:inherit;
}


@media screen and (max-width: 1100px) {

    h1{
           font-size: 50px;
       }

    h2{
        font-size: 34px;
    }

    a {
        color: $primary-color;
        font-size: 22px;
        font-weight: lighter !important;
    }

}

@media screen and (max-width: 800px) {
    .App-wrapper{
        padding: 0 50px;
    }
}



@media screen and (max-width: 600px) {
    h1{
        font-size: 40px;
    }

    h2{
        font-size: 24px;
    }

    a {
        font-size: 22px;
    }
}

@media screen and (max-width: 400px) {
    .App-wrapper{
        padding: 0 25px;
    }
}
